<template>
  <Row justify="between">
    <Column justify="center"
            class="grid-md-5 grid-lg-5 rich-story__meta-holder">
      <slot name="rich-story-title"/>
      <slot name="rich-story-description"/>
      <slot/>
    </Column>
    <Column class="grid-md-6 grid-lg-6 rich-story__image-holder">
      <img class="rich-story__image"
           :src="$city && $city.image">
    </Column>
  </Row>
</template>

<script>
export default {
  name: 'RichStory',
};
</script>
